import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PageHeader from '@ui/PageHeader';
import SEO from '@components/seo';
import useAxios from 'axios-hooks';
import { learnMoreDetails } from '@data';
import URLS from '@config/urls';
import useClippy from 'use-clippy';
import CommunitySignUp from '@components/Miscellaneous/CommunitySignUp';
import DownloadBoardmakerCard from '@components/Downloads/DownloadBoardmakerCard';
import { SystemRequirementsModal } from '@components/Details/SystemRequirements/SystemRequirements';

const formatLicenseKey = key =>
  key.replace(
    /^([A-Z0-9]{5})([A-Z0-9]{5})([A-Z0-9]{5})([A-Z0-9]{5})([A-Z0-9]{5})([A-Z0-9]{5})([A-Z0-9]{8})$/,
    '$1-$2-$3-$4-$5-$6-$7'
  );

export default function LicenseThankYou({ location }) {
  const { t } = useTranslation();
  const [clipboard, setClipboard] = useClippy();
  const purchaseInfo = location.state?.purchase;

  const [
    {
      data: licenseKeyData,
      loading: licenseKeyLoading,
      error: licenseKeyError,
    },
    getLicense,
  ] = useAxios(
    {
      url: URLS.LICENSE_KEY,
      headers: {
        'X-API-Version': '2.0',
      },
    },
    {
      manual: true,
    }
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const pageTitle = t('page:perpetualThankYou');

  useEffect(() => {
    if (purchaseInfo?.ref?.reference) {
      if (!licenseKeyData) {
        getLicense({
          params: {
            orderName: purchaseInfo.ref.reference,
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [licenseKeyData]);

  return (
    <>
      <SEO title={pageTitle} />
      <PageHeader className="mb-4">{pageTitle}</PageHeader>

      <p>
        {/* prettier-ignore */}
        <Trans i18nKey="perpetual.thankyou.intro">
          Look for your order confirmation email containing the Boardmaker download links and your activation key. We've also provided the information below. Download and install Boardmaker onto your computer, then use the activation key to activate your software. <b>Be sure to save your activation key for future use.</b>
        </Trans>
      </p>
      {purchaseInfo && (
        <div className="card bg-light py-4 px-4 my-4">
          <p className="my-0">
            {licenseKeyData?.licenseKeys?.length > 1
              ? 'Activation Keys'
              : 'Activation Key'}
          </p>
          {licenseKeyLoading && <p className="my-0">Loading...</p>}
          {licenseKeyError && (
            <div className="alert alert-danger my-3">
              Error loading activation keys.
            </div>
          )}
          {licenseKeyData?.licenseKeys?.map(l => (
            <button
              className="btn btn-link p-0 mx-0"
              id="activationKey"
              key={l.activationKey}
              title={'Copy activation key'}
              onClick={() => setClipboard(l.activationKey)}
            >
              {/* eslint-disable-next-line */}
              <p className="text-left">
                {formatLicenseKey(l.activationKey)}
                {
                  <svg
                    className="icon-copy icon-16 mx-2 mb-1"
                    title="Copy Icon"
                  >
                    <use xlinkHref="/assets/img/icon/icons.svg#icon-copy"></use>
                  </svg>
                }
              </p>
            </button>
          ))}
        </div>
      )}
      <DownloadBoardmakerCard setIsModalOpen={setIsModalOpen} />
      <CommunitySignUp includeBackButton={false} />
      <SystemRequirementsModal
        planDetails={learnMoreDetails.boardmakerLicense}
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(false)}
      />
    </>
  );
}
